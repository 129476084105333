<template>
<div>
    <van-nav-bar :title="title" left-text="" left-arrow @click-left="onClickLeft" right-text="筛选" style="margin-bottom:15px;" />
	<van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
		
			<van-swipe-cell v-for="item in order_list" class="order_list" :key="item.id">
				<van-cell  :label="item.area" :to="{ path: '/orderinfo', query: { id: item.id,union:item.union,type:type }}" >
					<template #title>
						<span class="order_name">{{item.name}}</span><span class="order_tel">{{item.tel}}</span>
						<van-tag type="primary">{{item.union_name}}</van-tag>
					</template>
					<template  #value >
						<p class="order_category_name">{{item.category_name}}</p><!--<span class="order_goods_num">{{item.goods_num}}</span>-->
					</template>
				</van-cell>
				<!--<template #right>
					<van-button class="showboton" square type="primary" text="查看" @click="showinfo(item.union,item.id)" />
				</template>-->
			</van-swipe-cell>
		
	</van-list>

</div>
</template>
<script>
import { list, info, get_user } from '@/api/aftersalse'
export default {
  name: 'Aftersales',
	data() {
	  return {
	    finished:false,
		loading:false,
		order_list: {},
		infodialog:false,
		model:[],
		title:'待处理订单',
		status:this.$route.query.status,
		code:'',
		userid:'',
		type:0,
	  }
	},
	created() {
	  switch(this.status){
		  case '0': 
		  this.title='待处理订单' 
		  break
		  case '1': 
		  this.title='处理中订单' 
		  break
	  }
	 // alert(this.$route.name)
	  if(this.$route.name=='myorderlist'){
	 // alert(this.$route.query.code)
	  	this.type=1
			if(!this.$route.query.code){
				this.getcode()
			}
			else{
				if(this.status!=99){
					this.status=1
				}
				get_user({code:this.$route.query.code}).then(res => { 
				//alert(JSON.stringify(res.data))//成功
				    this.get_order_list(this.status,this.type,res.data.userid)
				  }).catch(() => {
				})
				//alert(this.$route.query.code)//可以
			}
	  }
	  else{
	  	this.get_order_list(this.status,this.type)
	  }
	},
	methods: {
	  get_order_list(status,type,userid){
	  	list({status:status,type:type,userid:userid}).then(res => {
			this.order_list=res.data.data.list
			//alert(JSON.stringify(res.data.data.list))
			this.finished = true
		  }).catch(() => {
			//this.loading = false
		})
	  },
	  onLoad(){
		  /*异步更新数据
		  setTimeout 仅做示例，真实场景中一般为 ajax 请求
		  setTimeout(() => {
			for (let i = 0; i < 10; i++) {
			  list.value.push(list.value.length + 1);
			}
	
			// 加载状态结束
			
	
			// 数据全部加载完成
			if (list.value.length >= 40) {
			  finished.value = true;
			}
		  }, 1000);*/
	  },
	  showinfo(union,id){
		  this.infodialog=true
		  info({id:id,union:union}).then(res => {
		  	this.model=res.data.data
		  	//alert(JSON.stringify(res.data.data.list))
		  	this.finished = true
		    }).catch(() => {
		  	//this.loading = false
		    })
	  },
	  onClickLeft(){
	    this.$router.back()
	  },
	  //获取企业微信网页授权链接code
	  getcode(){
		this.url=encodeURIComponent(window.location.href)
		this.getCodeUrl='https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww606e10828c960358&redirect_uri='+this.url+'&response_type=code&scope=snsapi_privateinfo&state=1&agentid=1000055#wechat_redirect'
		//alert(this.url)
		//alert(this.getCodeUrl)
	//this.getCodeUrl='https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww606e10828c960358&redirect_uri=http%3A%2F%2Faftersales.huahuamgjx.com%2Fmyorderlist%3Ftype%3D1&response_type=code&scope=snsapi_privateinfo&state=1&agentid=1000055#wechat_redirect'
			window.location.href = this.getCodeUrl
			this.code=this.$route.query.code
	  },
	}
}
</script>
<style>
.order_list{ margin-bottom:10px;}
.search{ background:#FFF;}
.order_name{ margin-right: 5px;}
.order_tel{ color:#333;margin-right: 5px;}
.showboton{height: 100%;}
.form{ font-size: 12px;}
.van-field__body{}
body{height:100%; background:#f0f0f0;}
.van-icon-search{font-size:32px;}
</style>


